$(document).ready(function () {
  console.log("app.js loaded");

  // Toggle submenus for mobile
  if ($(window).width() < 900) {
    $(".has-submenu").click(function () {
      console.log("clicked");
      $(this).find(".submenu").toggleClass("active");
    });
  } else {
    // Toggle submenus for desktop on hover
    $(".has-submenu").hover(
      function () {
        $(this).find(".submenu").addClass("active");
      },
      function () {
        $(this).find(".submenu").removeClass("active");
      }
    );
  }

  // Toggle mobile menu
  $("#hamburger").click(function () {
    $("#mobile-menu").toggleClass("active");
  });

  // Toggle submenus on mobile when clicking the dropdown icon
  $(".dropdown-icon").click(function () {
    $(this).next(".submenu").toggleClass("active");
  });
});
